import React from "react";

const PageContainer = ({
  children,
  title,
  description,
  className,
  fullWidth,
}) => {
  return <div className={`mx-auto ${className || ""}`}>{children}</div>;
};

export { PageContainer };
