// Used in gatsby node which doest not support es6 imports
const blogBaseUrl = "/actualites/";
const infoBaseUrl = "/info/";

module.exports.blogBaseUrl = blogBaseUrl;
module.exports.infoBaseUrl = infoBaseUrl;

module.exports.lightBackgroundColor = "bg-transparent";
module.exports.darkBackgroundColor = "bg-gray-50";

/**
 * List of the support language code we can use in our site.
 * it follows the standard IETF language tag format, which is :
 * [ISO 639 2-digits language code]-[ISO 3166 country code]
 */
const LANG_DE_DE = "de-DE";
const LANG_FR_BE = "fr-BE";
const LANG_FR_FR = "fr-FR";
// Catch-all lang to use if no other way, no country code specified on purpose
const LANG_EN = "en";

module.exports.LANG_DE_DE = LANG_DE_DE;
module.exports.LANG_FR_BE = LANG_FR_BE;
module.exports.LANG_FR_FR = LANG_FR_FR;
module.exports.LANG_EN = LANG_EN;

/**
 * Dictionnary of all the different homepages par language.
 * TODO: For now, it is hardcoded here, even if it points to a dynamic page where we manually set the slug.
 * So there is a risk the homepage does not exist, but for now it is sufficient for quick landing pages.
 * In the future, this will be reworked to become dynamic in Sanity or become environment variables.
 */
module.exports.LANGUAGE_TO_HOMEPAGE = Object.freeze({
  [LANG_DE_DE]: "/de",
  [LANG_FR_BE]: "/be",
  [LANG_FR_FR]: "/",
});
