import React from "react";
import { Link } from "gatsby";
import imageUrlBuilder from "@sanity/image-url";
import { darkBackgroundColor, lightBackgroundColor } from "./constants";

const builder = imageUrlBuilder({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
});

export const urlFor = asset => {
  return builder.image(asset).url();
};

export const blocksToText = (blocks, opts = {}) => {
  const defaults = { nonTextBehavior: "remove" };
  const options = Object.assign({}, defaults, opts);
  return blocks
    .map(block => {
      if (block._type !== "block" || !block.children) {
        return options.nonTextBehavior === "remove"
          ? ""
          : `[${block._type} block]`;
      }

      return block.children.map(child => child.text).join("");
    })
    .join("\n\n");
};

export const truncateBody = (body, n) => {
  const str = blocksToText(body);
  if (str.length <= n) return str;

  let truncatedString = str.substr(0, n);

  //re-trim if we are in the middle of a word
  truncatedString = truncatedString.substr(
    0,
    Math.min(truncatedString.length, truncatedString.lastIndexOf(" ")),
  );
  return truncatedString + String.fromCharCode(8230); // hellipsis
};

export const getSiblingProps = sibling => {
  if (!sibling || !Object.keys(sibling).length) return null;
  const {
    id,
    title,
    slug: { current },
  } = sibling;

  return { title, slug: current, id };
};

export const transformLink = href => {
  /* Transform inner links from absolute to relative links, and avoid
     accidentally sharing a link to a staging websites. */
  const omnidocHostnames = [
    "omnidoc.fr",
    "www.omnidoc.fr",
    "preview.hq.omnidoc.fr",
    "landing.hq.omnidoc.fr",
  ];
  try {
    /* Relative urls are not valid per the URL constructor, it throws
       an exception when trying to build the URL object with one. */
    const url = new URL(href);
    if (omnidocHostnames.includes(url.hostname)) {
      return href.split("omnidoc.fr").pop();
    }
  } catch (e) {}

  return href;
};

export const isLinkRelative = href =>
  !new RegExp("^(?:[a-z+]+:)?//", "i").test(href);

export const SmartLink = ({ href, preventGatsbyLink, children }) => {
  const transformedLink = transformLink(href);

  // www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#file-downloads
  const isFile = /\.[0-9a-z]+$/i.test(href);

  if (isLinkRelative(transformedLink) && !isFile) {
    // Gatsby's `Link` does not work if the link is a shortLink.
    // Could we check against the url belongs to the list of shortlinks ?
    if (preventGatsbyLink) {
      return <a href={href}>{children}</a>;
    } else {
      return <Link to={transformedLink}>{children}</Link>;
    }
  }
  return (
    <a href={transformedLink} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

/**
 * Get all the listed params and returns a single className string with all the truthy values
 */
export const classNames = (...classes) => classes.filter(Boolean).join(" ");

/**
 * Small util function to get the opposite backgroundColor
 */
export const getOppositeBackgroundColor = backgroundColor =>
  backgroundColor === lightBackgroundColor
    ? darkBackgroundColor
    : lightBackgroundColor;
