import * as React from "react";
import { SectionContainer } from "../layout";
import { FooterFrenchNavigation } from "./FooterFrenchNavigation";
import { LANG_DE_DE, LANG_EN, LANG_FR_BE, LANG_FR_FR } from "../../constants";

const LANGUAGE_TO_FOOTER_NAVIGATION = {
  [LANG_FR_FR]: FooterFrenchNavigation,
};

const LANGUAGE_TO_ALL_RIGHTS = {
  [LANG_FR_FR]: "Omnidoc, tous droits réservés.",
  [LANG_FR_BE]: "Omnidoc, tous droits réservés.",
  [LANG_DE_DE]: "Omnidoc, alle Rechte vorbehalten.",
  [LANG_EN]: "Omnidoc, all rights reserved.",
};

// All the langs where we remove the padding top of the footer. @todo update once we have correct navigation
const NO_PADDING_TOP_LANGUAGES = [LANG_FR_BE, LANG_DE_DE];

/**
 * The bottom Foooter, language dependant. Defaults to french
 * Contains the bottom navigation and the copyrights
 */
export const Footer = ({ language = LANG_FR_FR }) => {
  // If given language has no translation, defaults to english
  const allRights =
    LANGUAGE_TO_ALL_RIGHTS[language] || LANGUAGE_TO_ALL_RIGHTS[LANG_EN];

  return (
    <SectionContainer
      fullWidth
      removePaddingTop={NO_PADDING_TOP_LANGUAGES.includes(language)}
    >
      <footer className="bg-white" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        {LANGUAGE_TO_FOOTER_NAVIGATION[language] && (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-12 ">
            {LANGUAGE_TO_FOOTER_NAVIGATION[language]}
          </div>
        )}
        <div className="border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            &copy; {new Date().getFullYear()} {allRights}
          </p>
        </div>
      </footer>
    </SectionContainer>
  );
};
